<template>
  <div class="login-box">
    <swipeTopVue
      :top_img="top_img"
      :ZHtitle="ZHtitle"
      :ENtitle="ENtitle"
    ></swipeTopVue>
    <div class="loginBox">
      <van-form @submit="onSubmit">
        <div class="formItem">
          <span class="titleName">用户名<span class="icon">*</span></span>
          <van-field
            v-model="username"
            name="username"
            placeholder="请输入用户姓名"
            :rules="[{ required: true, message: '请输入用户名' }]"
          />
        </div>
        <div class="formItem">
          <span class="titleName">密码<span class="icon">*</span></span>
          <van-field
            v-model="password"
            name="password"
            placeholder="请输入密码"
            type="password"
            :rules="[{ required: true, message: '请输入密码' }]"
          />
        </div>
        <div @click="toRegistered" class="toRegistered">
          <span>注册账号>></span>
        </div>
        <div style="margin: 50px 0; width: 80%">
          <van-button round block type="info" native-type="submit"
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import swipeTopVue from "../../components/swipeTop.vue";
import { userLogin1 } from "../../api/path";
export default {
  components: {
    swipeTopVue,
  },
  data() {
    return {
      top_img:
        "https://sdg-minisite-prod-s3.s3.cn-northwest-1.amazonaws.com.cn/SDGimages/menu-background/ppjs-banner-4-1.jpg",
      ZHtitle: "登录",
      ENtitle: "LOGIN",
      username: "",
      password: "",
    };
  },
  created() {},
  methods: {
    onSubmit(value) {
      this.$toast({
        type: "loading",
        message: "登录中...",
        duration: 1500,
      });
      setTimeout(() => {
        userLogin1(JSON.stringify(value)).then((res) => {
          if (res.code === 200) {
            this.$toast({
              type: "success",
              message: "登录成功",
              duration: 1000,
            });
            this.$cookies.set("userInfo", res.data.authInfo.id);
            this.$cookies.set("accessToken", res.data.authInfo.accessToken);
            setTimeout(() => {
              // this.$cookies.set("pathUrl", this.$route.fullPath);
              this.$router.push({
                path: this.$cookies.get("pathUrl"),
              });
            }, 1000);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }, 1500);
    },
    toRegistered() {
      this.$router.replace({
        path: "registeredPage",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .loginBox {
    background-color: #740709;
    flex: 1;
    .titleName {
      display: inline-block;
      margin: 40px 0 10px 0;
      color: #ffffff;
      .icon {
        color: red;
      }
    }
    .van-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20%;
      .formItem {
        width: 90%;
      }
      .toRegistered {
        display: flex;
        color: white;
        width: 85%;
        justify-content: flex-end;
        margin-top: 10px;
      }
      .van-field {
        padding: 4px 16px;
        border-radius: 10px;
      }
    }
  }
}

/deep/ .van-button--small {
  width: 80px;
}
</style>